import React from "react"
import styled from "styled-components"
import Footer from "../../components/footer"
import { Helmet } from "react-helmet"

import { Row, Col } from "antd"

import { FaWhatsapp } from "react-icons/fa"

export default class Marketing extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Vaga para Analista de Marketing</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Gosta de trabalhar com publicidade e marketing? Vem para a deli use a sua criatividade para enganjar clientes e trazer tecnlogia ao mundo da gastronomia!"
          />
        </Helmet>
        <Header>
          <Row style={{ height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Righteous",
                  fontSize: "1.8em",
                  width: "64px",
                  margin: "0px",
                }}
              >
                deli
              </p>
            </Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "24px",
              }}
            >
              <FaWhatsapp size="1.78em" />
              <span
                style={{
                  fontFamily: "IBM Plex Sans",
                  color: "#3f6aea",
                  fontSize: "1.2em",
                  marginLeft: "6px",
                }}
              >
                {"(41) 9 9757-4479"}
              </span>
            </Col>
          </Row>
        </Header>
        <TextSection>
          <Center>
            <Valign>
              <TextContainer>
                <h3
                  style={{ fontFamily: "IBM Plex Sans", marginBottom: "24px" }}
                >
                  VAGA PARA ANALISTA DE MARKETING EM CURITIBA
                </h3>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                  }}
                >
                  Sobre nós:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Para nós, a comunicação com clientes é fundamental. Não é
                  fácil para os restaurantes, cafés e similares trocarem o
                  sistema de ponto de venda. Mesmo que a mudança seja para
                  melhor, existem atritos a serem vencidos porque a operação dos
                  estabelecimentos não pode ser interrompida. Portanto, é
                  responsabilidade da equipe de marketing/vendas desenvolver
                  estratégias eficazes para atender cada vez melhor as
                  necessidades dos nossos clientes.
                </p>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Sobre esta vaga:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Procuramos alguém apaixonado por leitura e redação e que tenha
                  criatividade para criar publicidade marcante e eficaz. Além
                  disso, queremos uma pessoa que consiga interpretar os
                  feedbacks dados por nossos clientes.
                </p>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Responsabilidades:
                </h4>
                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Trabalhar juntamente com equipe de design na elaboração de
                    propagandas
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Gerenciar e criar posts em mídias sociais
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Organizar eventos e workshops
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Edição de textos
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Criar apresentações e catálogos
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Fazer prospecção de potenciais clientes e divulgar serviços
                    da empresa
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Colaborar com nossas equipes e stakeholders
                  </li>
                </ul>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Aquilo que procuramos:
                </h4>

                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Ensino superior na área de publicidade, propaganda,
                    marketing ou similar
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Domínio da Língua Portuguesa e excelente redação
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Noções de deisgn e capacidade para desenvolver publicidade
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Participação em projetos que usam Google Analytics
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Um portfolio que mostra apresentações que vão além do
                    convencional
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Experiência com negócios web
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Inglês fluente
                  </li>
                </ul>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Tem interesse?
                </h4>

                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Se você se interessou, envie seu currículo e portfolio para
                  <b style={{ color: "#3f6aea" }}> vagas@delipos.com.br</b>
                </p>
              </TextContainer>
            </Valign>
          </Center>
        </TextSection>
        <Footer />
      </div>
    )
  }
}

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  color: #3f6aea;
  height: 48px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

const TextSection = styled.div`
  padding-top: 48px;
  width: 100%;
  min-height: 95vh;
  background: white;
`

const TextContainer = styled.div`
  display: inline-block;
  @media (min-width: 992px) {
    width: 600px;
  }

  @media (max-width: 993px) {
    width: 90%;
  }
`

const Center = styled.div`
  display: table;
  width: 100%;
  height: auto;
`
const Valign = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`
